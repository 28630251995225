<template>
  <div>
    <div class="contentsWidth mx-auto mt-4">
      <div class="">
        <p class="title mb-0">期間一括設定</p>
        <hr class="title">
      </div>


      <div
        class="contentsWidth mx-auto mt-4">
        <p>チェックがついている項目の内容を対象の助成プログラムに一括設定します。<br>
          一括設定が不要な助成プログラム、項目はチェックを外してください。</p>
      </div>
      <div v-if="dbSeriesList.length > 0" id="serch-wrap" class="">
        <div class="bold">対象の助成プログラム</div>
        <div class="search-area flex flexWrap flexCenter">
          <div v-for="(series, key) in seriesList" :key="key" class="inlineBlock search-space targetSeries">
            <b-form-checkbox
              class="collective-checke"
              v-model="targetAppSeries"
              :value="series.value"
              switches>{{series.text}}</b-form-checkbox>
          </div>
        </div>
      </div>
      <b-tabs
        class="gas-tab"
        nav-wrapper-class="gas-tab-wrap">
        <b-tab
          title-link-class="px-5">
          <v-date-form
            :errors.sync="errors"
            :now.sync="now"
            :noInput.sync="noInput"
            :isInterimApp="isInterimApp"
            :isCollectiveSetting="true"
            :selectedSettignTargetList="selectedSettignTargetList"
            @updateselectedSettignTargetList="updateselectedSettignTargetList"/>
          <template v-slot:title>
            <span>期間設定</span>
            <img
              v-show="Object.keys(errors).length > 0"
              class="errorMark"
              src="@/assets/svg/error.svg">
          </template>
        </b-tab>
      </b-tabs>

      <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
        <b-button
          class="btn btn-lg bold mr-4"
          to="/cms/form/edit">戻る</b-button>
        <b-button
          @click="save"
          variant="primary"
          class="btn btn-lg bold">保存</b-button>
      </div>

    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import download from '@/modules/download';
import DateForm from '@/components/cms/form/Date.vue';

export default {
  name: 'CmsFormEditView',
  components: {
    // eslint-disable-next-line
    'v-date-form': DateForm,
  },
  data() {
    return {
      now: moment(),
      dbSeriesList: [],
      errors: {},
      fileErrors: {},
      evalErrors: {},
      criteriaErrors: {},
      noInput: false,
      selectedSettignTargetList: ['1', '2', '3', '4'],
      targetYear: null,
      targetAppSeries: [],
      InterimRequiredApp: [2, 8, 9, 5],
    };
  },
  computed: {
    seriesList() {
      const filterSeriesList = this.dbSeriesList.filter((data) => {
        return (this.targetYear !== null && this.targetYear < 2025) || data.id !== 9;
      });
      return filterSeriesList.map((series) => {
        if (this.targetYear !== null) {
          if (this.targetYear < 2025 && series.id === 2) {
            return { value: series.id, text: '調査研究助成' };
          }
          if (this.targetYear < 2025 && series.id === 8) {
            return { value: series.id, text: '目的型調査研究助成' };
          }
        }

        return { value: series.id, text: series.name };
      });
    },
    isInterimApp() {
      let isInterimAppTarget = false;
      Object.keys(this.InterimRequiredApp).forEach((key) => {
        if (this.targetAppSeries.includes(this.InterimRequiredApp[key])) {
          isInterimAppTarget = true;
        }
      });
      return isInterimAppTarget;
    },
    apptypeId() {
      return this.$store.state.cmsForm.apptypeId;
    },
    apptype() {
      return this.$store.state.cmsForm.apptype;
    },
    stages() {
      return this.$store.state.cmsForm.stages;
    },
    fileAppForm() {
      return this.$store.state.cmsForm.fileAppForm;
    },
    fileAcceptance() {
      return this.$store.state.cmsForm.fileAcceptance;
    },
    fileCostList() {
      return this.$store.state.cmsForm.fileCostList;
    },
    fileReport() {
      return this.$store.state.cmsForm.fileReport;
    },
    fileCostReport() {
      return this.$store.state.cmsForm.fileCostReport;
    },
    files() {
      return this.$store.state.cmsForm.files;
    },
    interimRepFiles() {
      return this.$store.state.cmsForm.interimRepFiles;
    },
    reportFiles() {
      return this.$store.state.cmsForm.reportFiles;
    },
    requiredDocFiles() {
      return this.$store.state.cmsForm.requiredDocFiles;
    },
    changePlanFiles() {
      return this.$store.state.cmsForm.changePlanFiles;
    },
    realFiles() {
      return this.$store.state.cmsForm.realFiles;
    },
    evaluations() {
      return this.$store.state.cmsForm.evaluations;
    },
    criteria() {
      return this.$store.state.cmsForm.criteria;
    },
  },
  methods: {
    updateselectedSettignTargetList(list) {
      this.selectedSettignTargetList = list;
      console.log(list);
    },
    init() {
      this.errors = {};
      this.fileErrors = {};
      this.evalErrors = {};
      this.criteriaErrors = {};
      this.now = moment();
    },
    async initFetch() {
      const { year } = this.$route.params;
      this.targetYear = year;
      const response = await api.sendForm('/api/cms/form/period/init')
        .catch((error) => {
          console.log(error);
          return false;
        });
      if (response === false) {
        console.error('error');
      }
      this.$store.commit('cmsForm/setOldYearData', response.data.backYearData);
      this.dbSeriesList = response.data.seriesList;
      Object.keys(this.dbSeriesList).forEach((key) => {
        if (this.dbSeriesList[key]) {
          if (this.dbSeriesList[key].id === 9 && this.targetYear >= 2025) {
            return;
          }
          this.targetAppSeries.push(this.dbSeriesList[key].id);
        }
      });
    },
    async save() {
      let targetApp = '';
      let isAll = true;
      Object.keys(this.dbSeriesList).forEach((key) => {
        if (this.dbSeriesList[key]) {
          if (this.targetAppSeries.includes(this.dbSeriesList[key].id)) {
            targetApp += `、${this.dbSeriesList[key].name}`;
          } else {
            if (this.dbSeriesList[key].id === 9 && this.targetYear >= 2025) {
              return;
            }
            isAll = false;
          }
        }
      });
      if (isAll) {
        targetApp = '、すべての助成プログラム';
      }
      const msg = `期間を一括設定します。\n設定内容は${targetApp}に保存されます。\n保存してもよろしいですか？`;
      if (!await this.confirm(msg)) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      this.init();
      const params = {
        apptype: JSON.stringify(this.apptype),
        targetApp: JSON.stringify(this.targetAppSeries),
        stages: JSON.stringify(this.stages),
        year: this.targetYear,
        targetItem: JSON.stringify(this.selectedSettignTargetList),
      };
      const response = await api.sendForm('/api/cms/form/period/save', params)
        .catch(async (error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.messages;
            await this.alert('入力内容に誤りがありました。');
          } else {
            await this.alert('保存に失敗しました。');
          }
          return false;
        });
      if (response === false) {
        this.$store.dispatch('page/offWaiting');
        return;
      }
      await this.alert('保存しました。', false);
      this.$store.dispatch('page/offWaiting');
      this.$router.push({ name: 'cmsFormEdit' });
    },
    async downloadFile(assetId, fileName, type) {
      const param = {
        assetId,
        type,
        fileName,
      };
      const url = '/api/cms/form/download';

      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
  },
  async created() {
    this.$store.dispatch('page/onWaiting');
    this.$store.commit('cmsForm/init');
    await this.initFetch();
    this.$store.dispatch('page/offWaiting');
  },
};
</script>

<style scoped>
  #serch-wrap input, #serch-wrap select {
    height: 50px;
  }

  .year-select {
    height: 40px;
    width: 120px;
  }

  .search-area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }
  .search-space {
    margin-right: 15px;
  }

  .{
    width: 520px;
  }

  .notice {
    color: #dc3545;
  }
  .errItem input{
    border:solid 1px #dc3545;
  }
  .error{
    font-size: 12.8px;
    color: #dc3545;
    display: block;
    width: 582px;
    margin-left: 15px;
    margin-top: 3px;
  }
  .errorMark {
    position: absolute;
    top: -7px;
    left: 150px;
  }
  .topBtnWrap {
    display: flex;
    justify-content: end;
  }
  .flexWrap{
    flex-wrap: wrap;
  }
  .targetSeries{
    min-width: 200px;
  }
  .targetSeries:nth-of-type(n+5){
    margin-top:20px;
  }
</style>
